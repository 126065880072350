<template>
	<div class="card card-custom card-stretch gutter-b antenna-wrapper">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">{{ name }} - Antenna Pointing</h3>
		</div>

		<div class="wrapper-compass" style="display: block; ">
			<div class="blockage-container">
				<svg>
					<circle
						v-for="(zone, i) in zones"
						:key="'A' + i"
						:style="{
							transform: 'rotate(' + zone.position + 'deg)',
							strokeDasharray: zone.blocksize + ' ' + zone.size,
						}"
						class="blockage"
						r="178"
						cx="178"
						cy="178"
					/>
				</svg>

				<div id="space">
					<div
						class="pointer"
						id="satellite-1"
						:style="{ transform: 'rotate(' + satellite_offset + 'deg)' }"
					>
						<div
							class="itc-icon-sat satellite"
							v-b-tooltip.html="
								data.SamplingTimestamp.value + 
                '<br>Heading: ' + (data.Heading.value ? data.Heading.value + '&deg;': 'n/a') +
								'<br>Status Lock: ' +
								(data.StatusLock.value ? data.StatusLock.value : 'n/a') +
								'<br>Status Mute: ' +
								(data.StatusMute.value ? data.StatusMute.value : 'n/a') + 
                '<br>Error Status: ' + (data.StatusError.value ? data.StatusError.value : 'n/a')
							"
						/>
					</div>

					<div id="compass">
						<span class="direction">0&#730;</span>
						<span class="direction">90&#730;</span>
						<span class="direction">180&#730;</span>
						<span class="direction">270&#730;</span>

						<div class="compass-grid cg1"></div>
						<div class="compass-grid cg2"></div>
						<div class="compass-grid cg3"></div>
						<div class="compass-grid cg4"></div>

						<div>
							<img
								src="/media/antenna/satellite-dish-solid.svg"
								alt="satellite"
								id="dish-1"
								class="dish"
								:style="{ transform: 'rotate(' + antenna_offset + 'deg)' }"
							/>
							<img
								src="/media/antenna/boat.svg"
								alt="ship"
								id="ship-1"
								class="ship"
								:style="{ transform: 'rotate(' + heading + 'deg)' }"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AntennaPointing',
	props: ['id','name'],
	data() {
		return {
			data: {},
			antenna_offset: 0,
			satellite_offset: 0,
			zones: [],
			blockageZones: null,
			radius: 178,
			windowSize: 1920,
			heading: 0,
			dish: 'loading...', //abs_Azimuth
			elevation: 'loading...',
			antennaID: 0,
			satellite: 'loading...',
		};
	},
	watch: {
        windowSize(newVal) {
            if(newVal<1273) {
				this.radius = 90;
				this.createZones(this.blockageZones);
			}
			else if(newVal<1701) {
				this.radius = 128;
				this.createZones(this.blockageZones);
			}
			else {
				this.radius = 178;
				this.createZones(this.blockageZones);
			}
        },
    },
	methods: {
		adjustRadius(arr) {
			arr.map((item) => {
				item.setAttribute('r', 250);
			});
		},

		createZones(blockageZones) {
			this.zones = []; //reset array when blockageZones updates
			blockageZones.map((zone) => {
				let arr = zone.split(','); // input e.g., "ENABLED,5,90,90"
				let obj = {};
				obj.blockage = arr;
				obj.position = parseInt(arr[1]) + parseInt(this.heading);
				obj.size = 2 * Math.PI * this.radius;
				obj.blocksize = ((arr[2] - arr[1]) / 360) * obj.size;
				this.zones.push(obj);
			});
		},
		onResize() {
            this.windowSize = window.innerWidth;
        }
	},
	created() {
		this.loaded = false;
		this.$http.get(`swacustatus/${this.id}`).then((resp) => {
			this.data = resp.data.data;
			let data = resp.data.data;
			this.heading = data.Heading.value;

			this.blockageZones = data.BlockageZones.value.split(';');
			this.createZones(this.blockageZones);

			let azimuth = data.Azimuth.value;
			this.antenna_offset = parseFloat(azimuth, 10) - 45;
			this.satellite_offset = parseFloat(azimuth, 10) + 90;

			this.loaded = true;
		});
	},
	mounted() {
		window.addEventListener('resize', () => {
			this.windowSize = window.innerWidth
		})
	},
	beforeDestroy() { 
        window.removeEventListener('resize', this.onResize);
    },
};
</script>

<style scoped>
.status-label {
	display: flex;
	justify-content: space-between;
	margin-right: 5px;
	font-weight: bold;
	width: 84px;
}

.wrapper-compass {
	display: flex;
	/* flex-direction: column; */
	justify-content: center;
	align-content: center;
	height: 100%;
}

.blockage-container {
	position: relative;
}

#space {
	display: flex;
	position: relative;
	align-items: center;
	width: 400px;
	/* Testing small size to move the satellite inward for less text overlap. Old Val:: width:450px */
	height: 100%;

	margin: 0 auto;
}

#compass {
	z-index: 2;
	width: 356px;
	height: 356px;
	border-radius: 50%;
	margin: 0px auto;
	position: relative;

	border: 10px solid #002664;
	box-shadow: 0 0 4px #000, 0 0 1px 3px #fdc82f, 3px 6px 10px #48436f;
	overflow: hidden;
}

#compass:before {
	content: '';
	border: 2.2px dashed #747678;
	position: absolute;
	left: 2px;
	right: 2px;
	bottom: 2px;
	top: 2px;
	border-radius: 50%;
}

.direction {
	color: #000000;
}

.compass-grid {
	position: absolute;
	width: 100%;
	border: 0.25px dashed rgb(180, 180, 180);
	top: 49.5%;
	z-index: 98;
}

.cg1 {
	transform: rotate(90deg);
}

.cg2 {
	transform: rotate(45deg);
}

.cg3 {
	transform: rotate(-45deg);
}

svg {
	width: 356px;
	height: 356px;
	z-index: 1;
	position: absolute;
	top: 50%;
	margin-top: -178px;
	left: 50%;
	margin-left: -178px;
	/* -90 degree adjust offset, so blockages start at 0 */
	transform: rotate(-90deg);
	border-radius: 50%;
	background-color: white;
}

#blockage-0,
#blockage-1,
#blockage-2,
#blockage-3,
#blockage-4,
.blockage {
	fill: rgba(227, 230, 245, 0);
	stroke: rgba(77, 60, 60, 0.8);
	/* stroke: rgba(211, 8, 8, 0.8); */
	stroke-width: 356;
	transform-origin: 50%;
	/* 65.42 over 785 is the degrees of 360 */
	stroke-dasharray: 0 1118.4;
	/* 2π × radius ≈ 785 */
	/* width of blockage in degrees */
	/* transform: rotate(10deg); */
}

.blockage {
	/* right: 15px;
	top: 25px; */
	--offset: 45;
	--value: 120;
	height: 100%;
	width: 100%;
	position: absolute;
	transform: translate(0, 50%) rotate(90deg) rotate(0deg);
	transform-origin: 50%;
	/* transform-origin: 50% 100%; */
}

.blockage:before {
	background: rgba(77, 60, 60, 0.7);
	content: '';
	height: 100%;
	position: absolute;
	transform: translate(0, 100%) rotate(calc(var(--value, 45deg) * 1deg));
	transform-origin: 50% 0;
	width: 100%;
}

.blockage:after {
	background: #00f;
}

/* the directions - N E S W */
#compass span {
	position: absolute;
	font-weight: bold;
}

#compass span:nth-child(5) {
	width: 100%;
	text-shadow: rgb(8, 8, 8) 5px 5px 8px;
	color: #ffc400;
	font-size: 2rem;
	text-align: center;
	top: 100px;
	z-index: 200;
}

.dish {
	z-index: 200;
	position: absolute;
	left: 42%;
	top: 41.5%;
	width: 55px;
	transform-origin: 50% 50%;
}

.pointer {
	z-index: 99;
	background: black;
	box-shadow: var(--dish-pointer);
	-webkit-box-shadow: var(--dish-pointer);

	height: 2px;
	width: 50%;
	position: absolute;
	top: 49.5%;

	transform-origin: 100%;
	transform: rotate(0deg);
	transition-timing-function: ease-in-out;
	transition: all 0.7s;
}

.ship {
	transform-origin: 50% 50%;
	width: 100px;
	position: absolute;
	left: 36%;
	top: 29%;
}

.satellite {
	color: rgb(7, 37, 58);
	position: relative;
	margin-left: -50px;
	margin-top: -30px;
	width: 55px;
	transform: rotate(180deg);
	filter: var(--satellite-icon);
}

.compass-grid {
	border: 0.5px dashed rgb(180, 180, 180);
}

#compass {
	width: 356px;
	height: 356px;
	/* size/diameter of the compass/radar border/frame */
}

/* North */
#compass span:nth-child(1) {
	left: 164px;
	top: 15.6px;
}
/* South */
#compass span:nth-child(3) {
	left: 159px;
	bottom: 15.6px;
}

/* East */
#compass span:nth-child(2) {
	right: 11px;
	top: 158px;
}
/* West */
#compass span:nth-child(4) {
	left: 11px;
	top: 158px;
}

.satellite {
	font-size: 60px;
}

@media screen and (min-width: 1700px) {
	.antenna-wrapper {
		min-width: 411px;
	}
	.wrapper-compass {
		padding: 0 0 75px;
	}
}

@media screen and (max-width: 1700px) {
	.wrapper-compass {
		padding: 0 0 55px;
	}

	circle {
		r:128;
		cx:128;
		cy:128;
    }

	#compass {
		width: 256px;
		height: 256px;
		/* size/diameter of the compass/radar border/frame */
		border: 5px solid #002664;
		box-shadow: 0 0 4px #000, 0 0 1px 2px #fdc82f, 3px 6px 10px #48436f;
	}

	.blockage {
		/* fill: rgba(227, 230, 245, 0); */
		/* stroke: rgba(77, 60, 60, 0.8); */
				/* stroke: rgba(211, 8, 8, 0.8); */
		stroke-width: 256;
		/* transform-origin: 50%; */
				/* 65.42 over 785 is the degrees of 360 */
		stroke-dasharray: 0 804.25;
				/* 2π × radius ≈ 785 */
				/* width of blockage in degrees */
				/* transform: rotate(10deg); */
	}

	svg {
		width: 256px;
		height: 256px;
		margin-top: -128px;
		margin-left: -128px;
	}

	.dish {
		width: 40px;
	}

	.ship {
		width: 72.72px;
	}

	.satellite {
		font-size: 40px;
		margin-top: -20px;
		width: 50px;
		margin-left: -45px;
	}

	#space {
		width: 286px;
	}
	/* North */
	#compass span:nth-child(1) {
		left: 119px;
		top: 10px;
	}
	/* South */
	#compass span:nth-child(3) {
		left: 112px;
		bottom: 10px;
	}

	/* East */
	#compass span:nth-child(2) {
		right: 10px;
		top: 113px;
	}
	/* West */
	#compass span:nth-child(4) {
		left: 10px;
		top: 113px;
	}
}

@media screen and (max-width: 1272px) {
	.antenna-wrapper {
		min-width: 195px;
	}

	.wrapper-compass {
		padding: 20px 0 45px;
	}

	circle {
		r:90;
		cx:90;
		cy:90;
    }

	#compass {
		width: 180px;
		height: 180px;
		/* size/diameter of the compass/radar border/frame */
		border: 5px solid #002664;
		box-shadow: 0 0 4px #000, 0 0 1px 2px #fdc82f, 3px 6px 10px #48436f;
	}

	.blockage {
		/* fill: rgba(227, 230, 245, 0); */
		/* stroke: rgba(77, 60, 60, 0.8); */
				/* stroke: rgba(211, 8, 8, 0.8); */
		stroke-width: 180;
		/* transform-origin: 50%; */
				/* 65.42 over 785 is the degrees of 360 */
		stroke-dasharray: 0 565.5;
				/* 2π × radius ≈ 785 */
				/* width of blockage in degrees */
				/* transform: rotate(10deg); */
	}

	svg {
		width: 180px;
		height: 180px;
		margin-top: -90px;
		margin-left: -90px;
	}

	.dish {
		width: 28px;
	}

	.ship {
		width: 51px;
	}

	.satellite {
		font-size: 40px;
		margin-top: -20px;
		width: 50px;
		margin-left: -45px;
	}

	#space {
		width: 200px;
	}
	/* North */
	#compass span:nth-child(1) {
		left: 81px;
		top: 8px;
	}
	/* South */
	#compass span:nth-child(3) {
		left: 75px;
		bottom: 5px;
	}

	/* East */
	#compass span:nth-child(2) {
		right: 7px;
		top: 75px;
	}
	/* West */
	#compass span:nth-child(4) {
		left: 7px;
		top: 75px;
	}
}
</style>