var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom card-stretch gutter-b antenna-wrapper"},[_c('div',{staticClass:"card-header border-0"},[_c('h3',{staticClass:"card-title font-weight-bolder text-dark"},[_vm._v(_vm._s(_vm.name)+" - Antenna Pointing")])]),_c('div',{staticClass:"wrapper-compass",staticStyle:{"display":"block"}},[_c('div',{staticClass:"blockage-container"},[_c('svg',_vm._l((_vm.zones),function(zone,i){return _c('circle',{key:'A' + i,staticClass:"blockage",style:({
							transform: 'rotate(' + zone.position + 'deg)',
							strokeDasharray: zone.blocksize + ' ' + zone.size,
						}),attrs:{"r":"178","cx":"178","cy":"178"}})}),0),_c('div',{attrs:{"id":"space"}},[_c('div',{staticClass:"pointer",style:({ transform: 'rotate(' + _vm.satellite_offset + 'deg)' }),attrs:{"id":"satellite-1"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html",value:(
								_vm.data.SamplingTimestamp.value + 
                '<br>Heading: ' + (_vm.data.Heading.value ? _vm.data.Heading.value + '&deg;': 'n/a') +
								'<br>Status Lock: ' +
								(_vm.data.StatusLock.value ? _vm.data.StatusLock.value : 'n/a') +
								'<br>Status Mute: ' +
								(_vm.data.StatusMute.value ? _vm.data.StatusMute.value : 'n/a') + 
                '<br>Error Status: ' + (_vm.data.StatusError.value ? _vm.data.StatusError.value : 'n/a')
							),expression:"\n\t\t\t\t\t\t\t\tdata.SamplingTimestamp.value + \n                '<br>Heading: ' + (data.Heading.value ? data.Heading.value + '&deg;': 'n/a') +\n\t\t\t\t\t\t\t\t'<br>Status Lock: ' +\n\t\t\t\t\t\t\t\t(data.StatusLock.value ? data.StatusLock.value : 'n/a') +\n\t\t\t\t\t\t\t\t'<br>Status Mute: ' +\n\t\t\t\t\t\t\t\t(data.StatusMute.value ? data.StatusMute.value : 'n/a') + \n                '<br>Error Status: ' + (data.StatusError.value ? data.StatusError.value : 'n/a')\n\t\t\t\t\t\t\t",modifiers:{"html":true}}],staticClass:"itc-icon-sat satellite"})]),_c('div',{attrs:{"id":"compass"}},[_c('span',{staticClass:"direction"},[_vm._v("0˚")]),_c('span',{staticClass:"direction"},[_vm._v("90˚")]),_c('span',{staticClass:"direction"},[_vm._v("180˚")]),_c('span',{staticClass:"direction"},[_vm._v("270˚")]),_c('div',{staticClass:"compass-grid cg1"}),_c('div',{staticClass:"compass-grid cg2"}),_c('div',{staticClass:"compass-grid cg3"}),_c('div',{staticClass:"compass-grid cg4"}),_c('div',[_c('img',{staticClass:"dish",style:({ transform: 'rotate(' + _vm.antenna_offset + 'deg)' }),attrs:{"src":"/media/antenna/satellite-dish-solid.svg","alt":"satellite","id":"dish-1"}}),_c('img',{staticClass:"ship",style:({ transform: 'rotate(' + _vm.heading + 'deg)' }),attrs:{"src":"/media/antenna/boat.svg","alt":"ship","id":"ship-1"}})])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }